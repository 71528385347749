<template>
  <div>
    <BaseThemeComponent
      v-if="thePage.contents.show_home_hero"
      name="SectionHomeHero"
    />

    <BaseThemeComponent
      v-if="thePage.contents.tagline_title"
      name="PageSection"
      section-name="tagline"
      title-class="header-4 treps-section--title text-xs-center tagline"
      :title="thePage.contents.tagline_title"
    />

    <BaseThemeComponent
      v-if="thePage.contents.ns_1_title"
      section-id="2"
      name="PageSection"
      :bg-color="thePage.contents.ns_1_bg_color"
      class="pa-0 grid-list-xs home-view-menus-section"
      fluid
      full-bleed-bg
      full-bleed-content
    >
      <VLayout row wrap justify-center>
        <VFlex xs12 md6 class="text-xs-center left-side d-flex">
          <VLayout align-center>
            <VFlex class="pa-5">
              <h3 :class="['header-3', thePage.contents.ns_1_title_class]">
                <BasePageContent content-key="ns_1_title" />
              </h3>
              <div>
                <BaseButtonOutline
                  v-if="thePage.contents.ns_1_btn_text"
                  :to="thePage.contents.ns_1_btn_route"
                  :href="thePage.contents.ns_1_btn_link"
                  :outline-color="
                    thePage.contents.ns_1_btn2_color
                      ? thePage.contents.ns_1_btn2_color
                      : null
                  "
                  large
                >
                  {{ thePage.contents.ns_1_btn_text }}
                </BaseButtonOutline>
              </div>
              <div>
                <BaseButtonOutline
                  v-if="thePage.contents.ns_1_btn2_text"
                  :to="thePage.contents.ns_1_btn2_route"
                  :href="thePage.contents.ns_1_btn2_link"
                  :outline-color="
                    thePage.contents.ns_1_btn2_color
                      ? thePage.contents.ns_1_btn2_color
                      : null
                  "
                  large
                >
                  {{ thePage.contents.ns_1_btn2_text }}
                </BaseButtonOutline>
              </div>
            </VFlex>
          </VLayout>
        </VFlex>
        <VFlex xs12 md6>
          <VCard flat class="img-card" :img="thePage.contents.ns_1_img" />
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="thePage.contents.show_home_instagram"
      name="SectionInstagram"
      section-name="homeInstagram"
    />

    <BaseThemeComponent
      v-if="thePage.contents.ns_2_title"
      section-id="2"
      name="PageSection"
      :bg-color="thePage.contents.ns_2_bg_color"
      class="pa-0 grid-list-xs home-merch-section"
      fluid
      full-bleed-bg
      full-bleed-content
    >
      <VLayout row wrap justify-center class="pa-0">
        <VFlex xs12 md4 class="text-xs-center py-5 px-3 align-self-center">
          <h3 class="white--text header-2">
            <BasePageContent content-key="ns_2_title" />
          </h3>
          <h4 class="white--text">
            <BasePageContent content-key="ns_2_subtitle" />
          </h4>
          <BaseButtonOutline
            v-if="thePage.contents.ns_2_btn_text"
            :to="thePage.contents.ns_2_btn_route"
            :href="thePage.contents.ns_2_btn_link"
            large
          >
            {{ thePage.contents.ns_2_btn_text }}
          </BaseButtonOutline>
        </VFlex>
        <VFlex xs12 md8>
          <VCard flat class="img-card" :img="thePage.contents.ns_2_img" />
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_3_title ||
          thePage.contents.ns_3_description ||
          thePage.contents.ns_3_img
      "
      name="PageSectionNarrative"
      section-id="3"
      class="white--text"
      :text-cols="7"
      fluid
    >
      <VLayout row>
        <VFlex xs12 md10>
          <BasePageContent
            content-key="ns_3_description"
            tag="p"
            class="r-headline"
          />
          <BaseButtonOutline
            v-if="thePage.contents.ns_3_btn_text"
            :outline-color="thePage.contents.ns_3_btn_outline_color"
            class="ml-0"
            :to="thePage.contents.ns_3_btn_route"
            :href="thePage.contents.ns_3_btn_link"
          >
            {{ thePage.contents.ns_3_btn_text }}
          </BaseButtonOutline>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>
    <BaseMenuComponent name="MenuSnackbar" />
  </div>
</template>

<script>
import PageMixin from '@mixins/page'

export default {
  name: 'TrepsHome',
  components: {},
  mixins: [PageMixin],
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@design';
@import '@styleMixins';

.treps-section--title.tagline {
  @extend .freight-pro--font;

  text-transform: uppercase;
}

.home-merch-section,
.home-view-menus-section {
  .img-card {
    height: 100%;
    border-radius: 0;
  }
}
.home-view-menus-section {
  .left-side,
  .img-card {
    min-height: 75vh;
  }
}

.treps-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }

  @media (max-width: 650px) {
    padding: 3px;
  }
}
</style>
