var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.thePage.contents.show_home_hero)?_c('BaseThemeComponent',{attrs:{"name":"SectionHomeHero"}}):_vm._e(),(_vm.thePage.contents.tagline_title)?_c('BaseThemeComponent',{attrs:{"name":"PageSection","section-name":"tagline","title-class":"header-4 treps-section--title text-xs-center tagline","title":_vm.thePage.contents.tagline_title}}):_vm._e(),(_vm.thePage.contents.ns_1_title)?_c('BaseThemeComponent',{staticClass:"pa-0 grid-list-xs home-view-menus-section",attrs:{"section-id":"2","name":"PageSection","bg-color":_vm.thePage.contents.ns_1_bg_color,"fluid":"","full-bleed-bg":"","full-bleed-content":""}},[_c('VLayout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('VFlex',{staticClass:"text-xs-center left-side d-flex",attrs:{"xs12":"","md6":""}},[_c('VLayout',{attrs:{"align-center":""}},[_c('VFlex',{staticClass:"pa-5"},[_c('h3',{class:['header-3', _vm.thePage.contents.ns_1_title_class]},[_c('BasePageContent',{attrs:{"content-key":"ns_1_title"}})],1),_c('div',[(_vm.thePage.contents.ns_1_btn_text)?_c('BaseButtonOutline',{attrs:{"to":_vm.thePage.contents.ns_1_btn_route,"href":_vm.thePage.contents.ns_1_btn_link,"outline-color":_vm.thePage.contents.ns_1_btn2_color
                    ? _vm.thePage.contents.ns_1_btn2_color
                    : null,"large":""}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_1_btn_text)+" ")]):_vm._e()],1),_c('div',[(_vm.thePage.contents.ns_1_btn2_text)?_c('BaseButtonOutline',{attrs:{"to":_vm.thePage.contents.ns_1_btn2_route,"href":_vm.thePage.contents.ns_1_btn2_link,"outline-color":_vm.thePage.contents.ns_1_btn2_color
                    ? _vm.thePage.contents.ns_1_btn2_color
                    : null,"large":""}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_1_btn2_text)+" ")]):_vm._e()],1)])],1)],1),_c('VFlex',{attrs:{"xs12":"","md6":""}},[_c('VCard',{staticClass:"img-card",attrs:{"flat":"","img":_vm.thePage.contents.ns_1_img}})],1)],1)],1):_vm._e(),(_vm.thePage.contents.show_home_instagram)?_c('BaseThemeComponent',{attrs:{"name":"SectionInstagram","section-name":"homeInstagram"}}):_vm._e(),(_vm.thePage.contents.ns_2_title)?_c('BaseThemeComponent',{staticClass:"pa-0 grid-list-xs home-merch-section",attrs:{"section-id":"2","name":"PageSection","bg-color":_vm.thePage.contents.ns_2_bg_color,"fluid":"","full-bleed-bg":"","full-bleed-content":""}},[_c('VLayout',{staticClass:"pa-0",attrs:{"row":"","wrap":"","justify-center":""}},[_c('VFlex',{staticClass:"text-xs-center py-5 px-3 align-self-center",attrs:{"xs12":"","md4":""}},[_c('h3',{staticClass:"white--text header-2"},[_c('BasePageContent',{attrs:{"content-key":"ns_2_title"}})],1),_c('h4',{staticClass:"white--text"},[_c('BasePageContent',{attrs:{"content-key":"ns_2_subtitle"}})],1),(_vm.thePage.contents.ns_2_btn_text)?_c('BaseButtonOutline',{attrs:{"to":_vm.thePage.contents.ns_2_btn_route,"href":_vm.thePage.contents.ns_2_btn_link,"large":""}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_2_btn_text)+" ")]):_vm._e()],1),_c('VFlex',{attrs:{"xs12":"","md8":""}},[_c('VCard',{staticClass:"img-card",attrs:{"flat":"","img":_vm.thePage.contents.ns_2_img}})],1)],1)],1):_vm._e(),(
      _vm.thePage.contents.ns_3_title ||
        _vm.thePage.contents.ns_3_description ||
        _vm.thePage.contents.ns_3_img
    )?_c('BaseThemeComponent',{staticClass:"white--text",attrs:{"name":"PageSectionNarrative","section-id":"3","text-cols":7,"fluid":""}},[_c('VLayout',{attrs:{"row":""}},[_c('VFlex',{attrs:{"xs12":"","md10":""}},[_c('BasePageContent',{staticClass:"r-headline",attrs:{"content-key":"ns_3_description","tag":"p"}}),(_vm.thePage.contents.ns_3_btn_text)?_c('BaseButtonOutline',{staticClass:"ml-0",attrs:{"outline-color":_vm.thePage.contents.ns_3_btn_outline_color,"to":_vm.thePage.contents.ns_3_btn_route,"href":_vm.thePage.contents.ns_3_btn_link}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_3_btn_text)+" ")]):_vm._e()],1)],1)],1):_vm._e(),_c('BaseMenuComponent',{attrs:{"name":"MenuSnackbar"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }